import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";

const SalaryManage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [detail, setDetail] = useState("");
  const [error, setError] = useState(null);
  const [roundDate, setRoundDate] = useState(new Date());
  const [openPaid, setOpenPaid] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  const columns = [
    {
      id: "action",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    { id: "date", label: "งวดเงินเดือน", minWidth: 100 },
    { id: "round", label: "รอบที่", minWidth: 100 },
    { id: "detail", label: "รายละเอียด", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    { id: "paid", label: "วันที่จ่าย", minWidth: 100 },
    { id: "update", label: "อัปเดตล่าสุด", minWidth: 100 },
    {
      id: "check",
      label: "ชำระเงิน",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={row.status == "ชำระแล้ว"}
            onClick={() => handlePaid(row)}
          >
            <CheckIcon
              color={row.status == "ชำระแล้ว" ? "disabled " : "success"}
            />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={row.status == "ชำระแล้ว"}
            onClick={() => handleDelete(row)}
          >
            <DeleteForeverIcon
              color={row.status == "ชำระแล้ว" ? "disabled " : "error"}
            />
          </IconButton>
        </>
      ),
    },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditForm = (row) => {
    setOpenCreate(true);
    setIsEdit(true);
  };
  const handleCheckForm = (row) => {
    console.log("row", row);
    navigate("/salary-detail", {
      state: { params: row.id, date: row.date, round: row.round },
    });
  };

  const handlePaid = (row) => {
    console.log(row);
    setRowSelected(row);
    setOpenPaid(true);
  };

  const handleDelete = (row) => {
    console.log(row);
    setRowSelected(row);
    setOpenDelete(true);
  };

  const closePopup = () => {
    setError(null);
    setOpenCreate(false);
    setRoundDate(new Date());
    setDetail("");
    setRowSelected(null);
    setOpenPaid(false);
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(date, round, detail, status, paid, id) {
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    const statusFormat =
      status && status == "APPROVE" ? "ชำระแล้ว" : "รอการชำระ";
    return {
      date: date ? formattedDate : "-",
      round,
      detail: detail ? detail : "-",
      status: statusFormat,
      paid: paid ? paid : "-",
      id,
    };
  }

  const saveData = () => {
    if (roundDate) {
      let body = {
        mode: isEdit ? "edit" : "add",
        date: roundDate ? moment(roundDate).format("YYYY-MM-DD") : null,
        detail: detail,
        username: user.username,
      };
      api
        .post(`${config.mainAPI}/createAccountByMonth`, body)
        .then(function (response) {
          setIsLoading(false);
          if (response.data.success) {
            closePopup();
            getInitialData(user);
          } else {
            setError(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setError("Error can not do it.");
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      console.log("userData", userData);
      setUser(userData);
      getInitialData(userData);
    }
  }, []);

  const getInitialData = (userData) => {
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/listAccountByMonthAll`)
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        console.log("value", value);
        setList(value);
        setRows(
          value.map((item, index) =>
            createData(
              item.account_date,
              item.account_round,
              item.account_detail,
              item.account_status,
              item.account_paid,
              item.id
            )
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleClickOpenCreate = () => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setError(null);
    setData(christDate);
  };

  const savePaid = () => {
    let body = { ...rowSelected, username: user.username };
    api
      .post(`${config.mainAPI}/updateAccountByMonth`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          setOpenPaid(true);
          getInitialData(user);
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    setOpenPaid(false);
  };

  const deletePaid = () => {
    let body = { ...rowSelected, username: user.username };
    api
      .post(`${config.mainAPI}/deleteAccountByMonth`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          setOpenDelete(false);
          getInitialData(user);
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          จัดการเงินเดือน
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCreate(true)}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มรอบเงินเดือน
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => {
                        // Conditionally style the 'status' column
                        if (column.id === "status") {
                          return (
                            <TableCell
                              key={column.id}
                              align="center"
                              sx={{ textAlign: "-webkit-center" }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  borderRadius: "30px",
                                  padding: "0.3rem",
                                  backgroundColor:
                                    row.status === "ชำระแล้ว"
                                      ? "#086c3c"
                                      : "#f44336",
                                  maxWidth: "fit-content",
                                  width: "fit-content",
                                }}
                              >
                                {column.render
                                  ? column.render(row)
                                  : row[column.id]}
                              </Typography>
                            </TableCell>
                          );
                        }

                        // Render other columns normally
                        return (
                          <TableCell key={column.id} align="center">
                            <Typography>
                              {column.render
                                ? column.render(row)
                                : row[column.id]}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>

      <Dialog open={openCreate} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            เพิ่มรอบเงินเดือน
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                รอบวันที่: <span style={{ color: "red" }}> *</span>
              </Typography>

              <WatDatePicker
                className="custom-date-picker"
                value={roundDate}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setRoundDate, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>รายละเอียด:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopup()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => saveData()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openPaid} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ยืนยันการชำระเงิน
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography>
                หากยืนยันแล้ว ระบบจะทำการส่งอีเมล์เพื่อนำส่งใบเงินเดือน
                ไปยังเมล์แต่ละบุคคลที่ท่านทำรายการ
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopup()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => savePaid()}
              >
                ยืนยัน
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ยืนยันการลบ
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography>หากยืนยันแล้ว ข้อมูลทั้งหมดจะถูกลบ</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopup()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => deletePaid()}
              >
                ยืนยัน
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SalaryManage;
